import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: []
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}
		var facilityUnitGroups = [45321, 45311, 45312];

		var beds = 2;
		let el1 = document.querySelector("[data-beds]");
		if (el1) {
			beds = parseInt(el1.getAttribute('data-beds'), 10);
		} else {
			console.log('element is not found..................');
		}
		let el = document.querySelector("[data-unit-groups]");
		var unitGroupId = '';
		if (el) {
			//console.log('Unit groups::' + el.getAttribute('data-unit-groups'));
			var groupsAssignedToUnit = JSON.parse(el.getAttribute('data-unit-groups'));
			if (groupsAssignedToUnit) {
				for (var i = 0; i < groupsAssignedToUnit.length; i++) {
					if (facilityUnitGroups.includes(parseInt(groupsAssignedToUnit[i]))) {
						unitGroupId = parseInt(groupsAssignedToUnit[i]);
						//console.log('unitGroupId::' + unitGroupId);
					}
				}
			}

		} else {
			console.log('element is not found');
		}
		let el2 = document.querySelector("[data-unit-id]");
		var unitId = '';
		if (el2) {
			unitId = parseInt(el2.getAttribute('data-unit-id'));
			//console.log('unitId::' + unitId);
		} else {
			console.log('element is not found');
		}

		var req = {
			fields: this.resultFields,
			filter: {
				'beds': beds,
				unitgroupid: unitGroupId
			},
			max: 6,
			pricelist: true,
			sorting: 'random'
		};


		doReq('searchUnits', req)
			.then((res) => {
				//this.units = res.units;
				if (res.units) {
					res.units.forEach(u => {
						if (u._id != unitId) {
							this.units.push(u);
						}
					});
				}
				//console.log('res.units::' + res.units);
			});


	},
	updated: function () {
		this.applySlider();
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {

		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				//probably phone?
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},
		getUnitSearchPath: function (path, o) {
			return path;
		},


		applySlider: function () {
			$(".owl-carousel.similar-units").owlCarousel({
				loop: false,
				margin: 15,
				smartSpeed: 1000,
				autoplay: false,
				nav: true,
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 2
					},
					996: {
						items: 3
					},
					1200: {
						items: 4

					}
				}
			});
		}
	}

};