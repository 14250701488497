<template>
    <div>

        <div v-html="makeSeasonsRentalPrices()">

        </div>

    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'seasonprices',
    	props: {
    		pricelist: String,
    		groups: String
    	},
    	data: function() {
    		return {
    			groupedSeasons: [],
    			serviceByFinalCleaning: [],
    			priceListFilteredByRent: [],
    			priceListFilteredByTax: [],
    			serviceByBookingFee: [],
    			serviceByBettwaesche: [],
    			groupedSeasonsWithPrice: []

    		};
    	},
    	mounted: function() {
    		var pricelist = JSON.parse(this.pricelist);
    		//console.log('Pricelist::', pricelist);


    		/**
    		 * 1. filter all elements, which are of type 'RENT', are no surcharge and element has lines array
    		 */
    		const priceListFilteredByRent = pricelist.filter((priceListElement) => {
    			return priceListElement.service.type && priceListElement.service.type === 'RENT' && !priceListElement.service.surcharge && priceListElement.lines;
    		});

    		this.priceListFilteredByRent = priceListFilteredByRent;
    		//console.log('priceListFilteredByRent', priceListFilteredByRent);

    		if (priceListFilteredByRent) {
    			const priceListFilteredByRentLines = priceListFilteredByRent[0].lines;
    			// console.log('priceListFilteredByRentLines', priceListFilteredByRentLines);

    			/**
    			 * 2. reduce lines of filtered elements to only needed properties
    			 */
    			const priceListFilteredByRentMappedLines = priceListFilteredByRentLines.map(({
    				season, calculation, condition, fromdate, tilldate, value
    			}) => {
    				return {
    					season, calculation, condition, fromdate, tilldate, value
    				};
    			});
    			// console.log('priceListFilteredByRentMappedLines', priceListFilteredByRentMappedLines);

    			/**
    			 * 3. get grouped season of line elements
    			 */

    			/**
    			 * 3.1 get unique season names
    			 */
    			let seasonNames = [];
    			priceListFilteredByRentMappedLines.forEach(({
    				season
    			}) => {
    				if (!seasonNames.includes(season)) {
    					seasonNames.push(season);
    				}
    			});
    			// console.log('seasonNames', seasonNames);

    			/**
    			 * 3.2 group season periods for seasons
    			 */
    			let groupedSeasons = [];
    			seasonNames.forEach((seasonName) => {
    				const linesBySeasonName = priceListFilteredByRentMappedLines.filter(({
    					season
    				}) => {
    					return season === seasonName;
    				});
    				// console.log('linesBySeasonName', linesBySeasonName);

    				let seasonWithPeriods = {
    					name: seasonName,
    					seasonPeriods: []
    				};
    				linesBySeasonName.forEach(({
    					fromdate, tilldate
    				}) => {
    					const fromdateFormatted = new Date(fromdate).toLocaleDateString('de-DE', {
    						year: 'numeric',
    						month: '2-digit',
    						day: '2-digit'
    					});
    					const tilldateFormatted = new Date(tilldate).toLocaleDateString('de-DE', {
    						year: 'numeric',
    						month: '2-digit',
    						day: '2-digit'
    					});

    					const period = `${fromdateFormatted} - ${tilldateFormatted}`;
    					seasonWithPeriods.seasonPeriods.push(period);
    				});
    				if (seasonWithPeriods.seasonPeriods) {
    					groupedSeasons.push(seasonWithPeriods);
    				}
    			});

    			this.groupedSeasons = groupedSeasons;
    			//console.log('groupedSeasons', groupedSeasons);
    			this.makeSeasonsRentalPrices();


    		}

    	},
    	methods: {

    		makeSeasonsRentalPrices: function() {
    			let groupedSeasons = this.groupedSeasons;
    			let serviceByFinalCleaning = this.serviceByFinalCleaning;

    			let groupedSeasonsWithPrice = [];

    			var weeklyRentalPrice = 0;
    			var weeklyTaxPrice = 0;
    			var bookingFeePrice = 0;
    			var cleaningPrice = 0;
    			var bettwaeschePrice = 0;
    			var weeklyTotalPrice = 0;
    			var seasonObject = undefined;
    			var i = -1;
    			var entry = {};
    			var serviceText = '';
    			if (this.groups.includes(45739) || this.groups.includes(45311)) {
    				serviceText = 'inklusive Endreinigung';
    			} else {
    				serviceText = 'inklusive Endreinigung, Erstausstattung Bettwäsche und Handtücher und Energiekosten';
    			}
    			groupedSeasons.forEach((season) => {
    				i++;
    				//console.log(i);
    				seasonObject = this.calculateTotalPrice(season.name);
    				entry = {
    					'name': season.name,
    					'seasonPeriods': season.seasonPeriods,
    					'price': seasonObject.totalPrice / 100,
    					//'serviceText': seasonObject.serviceText
    					'serviceText': serviceText
    				}

    				groupedSeasonsWithPrice.push(entry);


    			});



    			//this.groupedSeasonsWithPrice = groupedSeasonsWithPrice;

    			//console.log('groupedSeasonsWithPrice', this.groupedSeasonsWithPrice);

    			return this.makeSeasonTable(groupedSeasonsWithPrice);

    		},

    		calculateTotalPrice: function(seasonName) {
    			var totalPrice = 0;
    			var servicesIncluded = 'Inklusive ';
    			var seasonObject = {};
    			//console.log('seasonName::' + seasonName);
    			var pricelist = JSON.parse(this.pricelist);


    			//For units under group 45321
    			if (this.groups.includes(45321)) {
    				//console.log('Unit belongs to group 45321');
    				for (let i = 0; i < pricelist.length; i++) {
    					var priceObj = pricelist[i];
    					//console.log('pricelist[0]::' + JSON.stringify(priceObj));
    					if (priceObj.service._id == 53687 || priceObj.service._id == 53689) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    							break;
    						}
    					}
    					if (priceObj.service._id == 53875) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    							break;
    						}
    					}
    					if (priceObj.service._id == 53698) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    							break;
    						}
    					}
    					if (priceObj.service._id == 53702) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    					if (priceObj.service._id == 53877) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}

    				}

    			}

    			//For units under group 45738
    			if (this.groups.includes(45738)) {
    				//console.log('Unit belongs to group 45738');
    				for (let i = 0; i < pricelist.length; i++) {
    					var priceObj = pricelist[i];
    					//console.log('pricelist[0]::' + JSON.stringify(priceObj));
    					if (priceObj.service._id == 53686 || priceObj.service._id == 53688) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    					if (priceObj.service._id == 53699) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    					if (priceObj.service._id == 53876) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    					if (priceObj.service._id == 51924) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    					if (priceObj.service._id == 53701) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}

    					if (priceObj.service._id == 53690 || priceObj.service._id == 53692) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}

    				}

    			}

    			//For units under group 45739
    			if (this.groups.includes(45739)) {
    				//console.log('Unit belongs to group 45739');
    				for (let i = 0; i < pricelist.length; i++) {
    					var priceObj = pricelist[i];
    					//console.log('pricelist[0]::' + JSON.stringify(priceObj));
    					if (priceObj.service._id == 53686 || priceObj.service._id == 53688) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    					if (priceObj.service._id == 53699) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    					if (priceObj.service._id == 53876) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    					if (priceObj.service._id == 53701) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    					if (priceObj.service._id == 53690) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    				}

    			}


    			//For units under group 45311
    			if (this.groups.includes(45311)) {
    				//console.log('Unit belongs to group 45311');
    				for (let i = 0; i < pricelist.length; i++) {
    					var priceObj = pricelist[i];
    					//console.log('pricelist[0]::' + JSON.stringify(priceObj));
    					if (priceObj.service._id == 50093 || priceObj.service._id == 53408) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    					if (priceObj.service._id == 51109) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    					if (priceObj.service._id == 52886) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    					if (priceObj.service._id == 52266) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    					if (priceObj.service._id == 50094 || priceObj.service._id == 51011) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    				}

    			}




    			//For units which doesn't belog to any of the above groups
    			if (!this.groups.includes(45321) && !this.groups.includes(45738) && !this.groups.includes(45739) && !this.groups.includes(45312)) {

    				for (let i = 0; i < pricelist.length; i++) {
    					var priceObj = pricelist[i];

    					if (priceObj.service._id == 53686 || priceObj.service._id == 53687 || priceObj.service._id == 53688 || priceObj.service._id == 53689) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    							break;
    						}
    					}
    					if (priceObj.service._id == 53875) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    							break;
    						}
    					}
    					if (priceObj.service._id == 53698) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    							break;
    						}
    					}
    					if (priceObj.service._id == 53702) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}
    					if (priceObj.service._id == 53877) {
    						if (priceObj.lines && priceObj.lines.length > 0) {
    							for (let j = 0; j < priceObj.lines.length; j++) {
    								var line = priceObj.lines[j];
    								if (line.season == seasonName) {
    									totalPrice += line.value;
    									servicesIncluded += priceObj.service.name + ", ";
    									break;
    								}
    							}
    						} else if (priceObj.price) {
    							totalPrice += priceObj.price;
    							servicesIncluded += priceObj.service.name + ", ";
    						}
    					}

    				}

    			}


    			seasonObject.totalPrice = totalPrice;
    			seasonObject.serviceText = servicesIncluded.substring(0, servicesIncluded.lastIndexOf(','));
    			return seasonObject;
    		},



    		makeSeasonTable: function(groupedSeasonsWithPrice) {
    			let data = groupedSeasonsWithPrice;
    			//console.log('data::', this.groupedSeasonsWithPrice);
    			var str = '';
    			str += '<table class="table pricelist table-striped">';
    			str += '<thead>';
    			str += '<tr>';
    			str += '<th scope="col">Saison</th>';
    			str += '<th scope="col">Zeiten</th>';
    			str += '<th scope="col">Preis pro Nacht</th>';
    			str += '</tr>';
    			str += '</thead>';

    			data.forEach((line) => {
    				str += '<tr>';
    				str += '<td>' + line.name + '</td>';
    				str += '<td>';
    				if (line.seasonPeriods) {
    					line.seasonPeriods.forEach((period) => {
    						str += period + '<br/>';
    					});
    				}
    				str += '</td>';

    				str += '<td>ab ' + line.price.toFixed(2).replace(".", ",") + ' &euro;';
    				str += '<br/><span class="small">' + line.serviceText + '</span>';
    				str += '</td>';
    				str += '</tr>';

    			});

    			str += '</table>';

    			return str;
    		}


    	},
    	computed: {

    	}
    };
</script>